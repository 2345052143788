// NewerGPT.js
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  Suspense,
} from 'react';
import ReactFlow, {
  addEdge,
  Handle,
  Background,
  Controls,
  MiniMap,
} from 'react-flow-renderer';
import { FaCopy, FaFlag, FaPaperPlane, FaSyncAlt } from 'react-icons/fa';
import dagre from 'dagre';
import io from 'socket.io-client';
import 'katex/dist/katex.min.css';
import renderTextWithFormatting from './renderTextWithFormatting';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';

// Import your visualization components
import RiemannSurfacePlot from './RiemannSurfacePlot';
import ComplexFunctionPlotterNode from './ComplexFunctionPlotterNode'; // Import the new node component

// Import the ReferenceNode component
import ReferenceNode from './ReferenceNode';

// Photos needed for the file.
import newImage1 from './img1.png';
import newImage2 from './img2.png';
import newImage3 from './img3.png';
import newImage4 from './img4.png';
import newImage5 from './img5.png';
import newImage6 from './img6.png';
import harvardLogoAM104 from './AM104.png';
import harvardLogoPhysics from './Physics.png'; // Replace with the actual path to your physics class image

function NewerGPT({
  openPrivacyOverlay,
  openTutorialOverlay,
  openExamplesOverlay,
  userInfo = { firstName: 'User', roles: [], courses: [] },
  status = 'Connected',
}) {
  const [selectedClass, setSelectedClass] = useState(
    userInfo.courses[0] || 'default_class'
  );

  const getClassLogo = () => {
    if (selectedClass === 'AM104') {
      return harvardLogoAM104;
    } else if (selectedClass === 'physics15a') {
      return harvardLogoPhysics;
    } else {
      return harvardLogoAM104; // Default image
    }
  };

  // Define class-specific initial options and images
  const classInitialOptions = {
    AM104: {
      prompts: [
        'Explain the key concepts from lecture 4 in a few sentences.',
        'I want to visualize the complex function log(z), plot it in 2D!',
        'I am confused on how to start section 3 question 1. Help me!',
      ],
      images: [newImage1, newImage2, newImage3],
      headings: [
        'Go over lecture concepts!',
        'Generate some visuals!',
        'Clear up confusion on section material!',
      ],
    },
    physics15a: {
      prompts: [
        'Explain a concept from the lecture!',
        'Can you suggest me problems from the textbook to help me?',
        'I am confused! Can you help me',
      ],
      images: [newImage4, newImage5, newImage6],
      headings: [
        'Lectures!',
        'Problem Bank',
        'Clarification!',
      ],
    },
    default_class: {
      prompts: [
        'I am having trouble with problem set 2, question 3. Can you help me?',
        'Explain the key concepts from the latest lecture.',
        'Can you visualize the function f(z) = sin(z) using the complex function plotter?',
      ],
      images: [newImage1, newImage2, newImage3],
      headings: [
        'Get Help on Problem Sets',
        'Review Lecture Concepts',
        'Visualize Functions',
      ],
    },
  };

  // Refs and State Variables
  const socketRef = useRef();
  const nextIdRef = useRef(1);
  const flowRef = useRef(null);
  const navigate = useNavigate(); // For logout

  // State for reactFlowInstance
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [input, setInput] = useState('');

  const [isHoveringOverPlotter, setIsHoveringOverPlotter] = useState(false);

  // Night mode state
  const [isNightMode, setIsNightMode] = useState(false);

  // State for settings modal
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  // Function to toggle night mode
  const toggleNightMode = () => {
    setIsNightMode((prevMode) => !prevMode);
  };

  // Function to get the next unique ID
  const getNextId = () => {
    const id = nextIdRef.current.toString();
    nextIdRef.current += 1;
    return id;
  };

  // Function to send user message
  const addUserMessage = useCallback(
    (messageText = input, isSuggested = false) => {
      if (messageText.trim()) {
        // Emit the message to the server
        socketRef.current.emit('add_message', {
          message: messageText,
          token: localStorage.getItem('token'), // Include token if necessary
        });

        // Create user node
        const userId = getNextId();
        const userNode = {
          id: userId,
          type: 'user',
          data: { label: `${messageText}` },
          position: { x: 0, y: 0 }, // Position will be set by layout
        };

        // Create bot node with empty label and assign isPlaceholder
        const botNodeId = getNextId();
        const botNode = {
          id: botNodeId,
          type: 'chatbot',
          data: {
            label: '',
            hasVisual: false,
            isPlaceholder: true,
          },
          position: { x: 0, y: 0 }, // Position will be set by layout
        };

        // Update nodes
        setNodes((prevNodes) => {
          // Remove initial options node if it exists
          const updatedNodes = prevNodes.filter(
            (node) => node.id !== 'initial-options'
          );
          updatedNodes.push(userNode, botNode);
          nodesRef.current = updatedNodes;
          return updatedNodes;
        });

        // Update edges
        setEdges((prevEdges) => {
          const updatedEdges = [...prevEdges];

          // Edge from user node to bot node
          updatedEdges.push({
            id: `e${userId}-${botNodeId}`,
            source: userId,
            target: botNodeId,
            type: 'smoothstep',
            animated: true,
          });

          // Edge from previous bot node to current user node (if any)
          const prevBotNode = nodesRef.current
            .slice(0, -2) // Exclude the last two nodes (current user and bot nodes)
            .reverse()
            .find((node) => node.type === 'chatbot');

          if (prevBotNode) {
            updatedEdges.push({
              id: `e${prevBotNode.id}-${userId}`,
              source: prevBotNode.id,
              target: userId,
              type: 'smoothstep',
              animated: true,
            });
          }

          edgesRef.current = updatedEdges;
          return updatedEdges;
        });

        // Clear input if not a suggested question
        if (!isSuggested) {
          setInput('');
        }
      }
    },
    [input]
  );

  // Memoize handleOptionClick to prevent re-renders
  const handleOptionClick = useCallback(
    (optionText) => {
      addUserMessage(optionText);
    },
    [addUserMessage]
  );

  // Initialize nodes with the initial options node
  const [nodes, setNodes] = useState([
    {
      id: 'initial-options',
      type: 'initialOptions',
      data: { onOptionClick: handleOptionClick },
      position: { x: 0, y: 0 }, // Position will be set by layout
    },
  ]);
  const [edges, setEdges] = useState([]);
  const [transform] = useState({ x: 0, y: 0, zoom: 1 });

  // Reference to the latest nodes and edges
  const nodesRef = useRef(nodes);
  const edgesRef = useRef(edges);

  // Update refs when state changes
  useEffect(() => {
    nodesRef.current = nodes;
  }, [nodes]);

  useEffect(() => {
    edgesRef.current = edges;
  }, [edges]);

  // Use dagre layout
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 500; // Use the node width we set in CSS
  const nodeHeight = 100; // Approximate node height

  const getLayoutedElements = useCallback(
    (nodesWithDimensions, edges, direction = 'TB') => {
      const isHorizontal = direction === 'LR';
      // Increase ranksep and nodesep for more vertical spacing
      dagreGraph.setGraph({ rankdir: direction, nodesep: 50, ranksep: 100 });

      // Separate main nodes and reference nodes
      const mainNodes = nodesWithDimensions.filter(
        (node) =>
          node.type !== 'reference' &&
          node.type !== 'suggestedQuestions' &&
          node.type !== 'visual' &&
          node.type !== 'complexFunctionPlotter'
      );
      const refNodes = nodesWithDimensions.filter(
        (node) =>
          node.type === 'reference' ||
          node.type === 'suggestedQuestions' ||
          node.type === 'visual' ||
          node.type === 'complexFunctionPlotter'
      );

      // Filter out edges connected to reference nodes
      const mainEdges = edges.filter((edge) => {
        const sourceNode = nodesWithDimensions.find(
          (n) => n.id === edge.source
        );
        const targetNode = nodesWithDimensions.find(
          (n) => n.id === edge.target
        );
        return (
          sourceNode.type !== 'reference' &&
          targetNode.type !== 'reference' &&
          sourceNode.type !== 'suggestedQuestions' &&
          targetNode.type !== 'suggestedQuestions' &&
          sourceNode.type !== 'visual' &&
          targetNode.type !== 'visual' &&
          sourceNode.type !== 'complexFunctionPlotter' &&
          targetNode.type !== 'complexFunctionPlotter'
        );
      });

      // Set nodes with actual dimensions
      mainNodes.forEach((node) => {
        const width = node.width || nodeWidth;
        const height = node.height || nodeHeight;
        dagreGraph.setNode(node.id, { width, height });
      });

      // Set edges for the main nodes
      mainEdges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
      });

      dagre.layout(dagreGraph);

      // Update positions of main nodes
      const layoutedNodes = mainNodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        node.position = {
          x: nodeWithPosition.x - (node.width || nodeWidth) / 2,
          y: nodeWithPosition.y - (node.height || nodeHeight) / 2,
        };

        return node;
      });

      // Now adjust the positions of the reference, suggested questions, visual, and complex function plotter nodes
      refNodes.forEach((node) => {
        // Find the source chatbot node
        const incomingEdge = edges.find(
          (edge) => edge.target === node.id && edge.source !== node.id
        );
        if (incomingEdge) {
          const chatbotNode = layoutedNodes.find(
            (n) => n.id === incomingEdge.source
          );
          if (chatbotNode) {
            if (node.type === 'reference') {
              // Position the reference node to the right of the chatbot node
              node.position = {
                x:
                  chatbotNode.position.x +
                  (chatbotNode.width || nodeWidth) +
                  50, // Adjust horizontal offset
                y: chatbotNode.position.y,
              };
              node.targetPosition = 'left';
              node.sourcePosition = 'right';
            } else {
              // Calculate the total height of the chatbot node and any nodes above it
              let accumulatedHeight =
                chatbotNode.position.y +
                (chatbotNode.height || nodeHeight) +
                50; // Initial spacing

              // If there are other refNodes connected to the same chatbotNode, adjust the accumulatedHeight
              const siblingNodes = refNodes.filter((n) => {
                const edge = edges.find(
                  (e) => e.source === chatbotNode.id && e.target === n.id
                );
                return (
                  edge &&
                  n.id !== node.id &&
                  n.position &&
                  n.position.y >= accumulatedHeight
                );
              });

              siblingNodes.forEach((sibling) => {
                accumulatedHeight =
                  sibling.position.y +
                  (sibling.height || nodeHeight) +
                  20; // Spacing between sibling nodes
              });

              // Position the node directly below the chatbot node or below the last sibling node
              node.position = {
                x:
                  chatbotNode.position.x +
                  ((chatbotNode.width || nodeWidth) -
                    (node.width || nodeWidth)) /
                    2, // Center align
                y: accumulatedHeight,
              };
              node.targetPosition = 'top';
              node.sourcePosition = 'bottom';
            }
          }
        }
      });

      // Combine all nodes
      const allNodes = [...layoutedNodes, ...refNodes];

      return { nodes: allNodes, edges };
    },
    []
  );

  // Function to handle socket responses
  const handleSocketResponse = useCallback((data) => {
    let botNodeId = null;

    setNodes((prevNodes) => {
      let updatedNodes = [...prevNodes];
      let hasChanged = false;

      for (let i = updatedNodes.length - 1; i >= 0; i--) {
        if (
          updatedNodes[i].type === 'chatbot' &&
          updatedNodes[i].data.isPlaceholder
        ) {
          const newData = {
            ...updatedNodes[i].data,
            label: updatedNodes[i].data.label + (data.text_delta || ''),
            isPlaceholder: !data.done,
            funcExpression: data.funcExpression || '',
            hasVisual: data.visualization_type ? true : false,
            visualizationType: data.visualization_type || '',
          };

          if (
            JSON.stringify(updatedNodes[i].data) !== JSON.stringify(newData)
          ) {
            updatedNodes[i] = {
              ...updatedNodes[i],
              data: newData,
            };
            botNodeId = updatedNodes[i].id;
            hasChanged = true;
          }
          break;
        }
      }

      // Handle citations (if any)
      if (data.done && data.citations && data.citations.length > 0) {
        const referenceNodeId = getNextId();
        const referenceNode = {
          id: referenceNodeId,
          type: 'reference',
          data: {
            references: data.citations,
          },
          position: { x: 0, y: 0 },
        };
        updatedNodes.push(referenceNode);

        // Add an edge from the chatbot node to the reference node
        if (botNodeId) {
          setEdges((prevEdges) => {
            const updatedEdges = [
              ...prevEdges,
              {
                id: `e${botNodeId}-${referenceNodeId}`,
                source: botNodeId,
                target: referenceNodeId,
                type: 'smoothstep',
                animated: true,
              },
            ];
            edgesRef.current = updatedEdges;
            return updatedEdges;
          });
        }
      }

      if (hasChanged) {
        nodesRef.current = updatedNodes;
        return updatedNodes;
      }
      return prevNodes;
    });
  }, []);

  // Function to handle visualization data
  const handleVisualizationData = useCallback(
    (data) => {
      console.log('Received Visualization Data:', data);

      const { plot_data, visualization_type } = data;

      if (visualization_type === 'complex_function_plotter') {
        // Handle the ComplexFunctionPlotterNode
        const plotterNodeId = getNextId();
        const plotterNode = {
          id: plotterNodeId,
          type: 'complexFunctionPlotter',
          data: {
            plotData: plot_data,
            funcExpression: plot_data.funcExpression,
          },
          position: { x: 0, y: 0 }, // Will be positioned during layout
          style: { width: '1200px', height: 'auto' }, // Adjust the node style
        };

        setNodes((prevNodes) => {
          const updatedNodes = [...prevNodes, plotterNode];
          nodesRef.current = updatedNodes;
          return updatedNodes;
        });

        // Find the latest chatbot node
        const chatbotNodes = nodesRef.current.filter(
          (node) => node.type === 'chatbot'
        );
        const originatingBotNode = chatbotNodes[chatbotNodes.length - 1];

        if (originatingBotNode) {
          // Add an edge from the chatbot node to the ComplexFunctionPlotterNode
          setEdges((prevEdges) => {
            const newEdges = [
              ...prevEdges,
              {
                id: `e${originatingBotNode.id}-${plotterNodeId}`,
                source: originatingBotNode.id,
                target: plotterNodeId,
                type: 'smoothstep',
                animated: true,
              },
            ];
            edgesRef.current = newEdges;
            return newEdges;
          });
        }
      } else if (visualization_type === 'generate_riemann') {
        // Existing visualization handling for 'generate_riemann'
        const realNodeId = getNextId();
        const imagNodeId = getNextId();

        // Prepare the new visual nodes
        const realVisualNode = {
          id: realNodeId,
          type: 'visual',
          data: {
            plotData: plot_data,
            funcExpression: plot_data.funcExpression,
            plotMode: 'real',
            visualizationType: visualization_type,
          },
          position: { x: 0, y: 0 }, // Position will be set later
          style: { width: '400px', height: '300px' },
        };

        const imagVisualNode = {
          id: imagNodeId,
          type: 'visual',
          data: {
            plotData: plot_data,
            funcExpression: plot_data.funcExpression,
            plotMode: 'imag',
            visualizationType: visualization_type,
          },
          position: { x: 0, y: 0 }, // Position will be set later
          style: { width: '400px', height: '300px' },
        };

        // Add the new visual nodes to the nodes state
        setNodes((prevNodes) => {
          const updatedNodes = [...prevNodes, realVisualNode, imagVisualNode];
          nodesRef.current = updatedNodes;
          return updatedNodes;
        });

        // Find the latest chatbot node
        const chatbotNodes = nodesRef.current.filter(
          (node) => node.type === 'chatbot'
        );
        const originatingBotNode = chatbotNodes[chatbotNodes.length - 1];

        if (originatingBotNode) {
          // Add edges from the chatbot node to the visualization nodes
          setEdges((prevEdges) => {
            const newEdges = [
              ...prevEdges,
              {
                id: `e${originatingBotNode.id}-${realNodeId}`,
                source: originatingBotNode.id,
                target: realNodeId,
                type: 'smoothstep',
                animated: true,
              },
              {
                id: `e${originatingBotNode.id}-${imagNodeId}`,
                source: originatingBotNode.id,
                target: imagNodeId,
                type: 'smoothstep',
                animated: true,
              },
            ];
            edgesRef.current = newEdges;
            return newEdges;
          });
        }
      }
    },
    [getNextId]
  );

  // Function to handle suggested questions data
  const handleSuggestedQuestions = useCallback((data) => {
    console.log('Received suggested questions:', data.questions);
    const { thread_id, message_index, questions } = data;

    // Find the corresponding chatbot node
    setNodes((prevNodes) => {
      const updatedNodes = [...prevNodes];
      const chatbotNode = updatedNodes.find(
        (node) =>
          node.type === 'chatbot' &&
          node.data.threadId === thread_id &&
          node.data.messageIndex === message_index
      );

      if (chatbotNode) {
        // Create a new node for suggested questions
        const suggestedQuestionsNodeId = getNextId();
        const suggestedQuestionsNode = {
          id: suggestedQuestionsNodeId,
          type: 'suggestedQuestions',
          data: {
            questions,
          },
          position: { x: 0, y: 0 }, // Will be set during layout
        };

        // Add the node to the nodes array
        updatedNodes.push(suggestedQuestionsNode);

        // Add an edge from the chatbot node to the suggested questions node
        setEdges((prevEdges) => {
          const updatedEdges = [
            ...prevEdges,
            {
              id: `e${chatbotNode.id}-${suggestedQuestionsNodeId}`,
              source: chatbotNode.id,
              sourceHandle: 'bottom', // Specify the source handle ID
              target: suggestedQuestionsNodeId,
              targetHandle: 'top', // Specify the target handle ID
              type: 'smoothstep',
              animated: true,
            },
          ];
          edgesRef.current = updatedEdges;
          return updatedEdges;
        });
      }
      nodesRef.current = updatedNodes;
      return updatedNodes;
    });
  }, []);

  // Initialize Socket Connection
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://gptharvard.uc.r.appspot.com', {
        // socketRef.current = io('http://localhost:8080', {
        transports: ['polling'],
        reconnectionAttempts: 5,
        timeout: 20000,
        path: '/socket.io',
        auth: {
          class_name: selectedClass, // Send class_name during connection
        },
      });
    }

    const socket = socketRef.current;
    socket.on('connect', () => {
      console.log('Server Connection');
      // Additional notification or actions can be added here
    });

    socket.on('final_response', (data) => {
      console.log('Receiving Response:', data); // Debug log
      handleSocketResponse(data);
    });

    socket.on('suggested_questions', (data) => {
      handleSuggestedQuestions(data);
    });

    socket.on('error', (error) => {
      console.error('Socket.IO Error:', error);
      socket.disconnect();
    });

    socket.on('reconnect_attempt', (attempt) => {
      console.log(`Reconnect attempt ${attempt}`);
      socket.disconnect();
    });

    socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      socket.disconnect();
    });

    socket.on('reconnect', (attemptNumber) => {
      console.log(`Reconnected successfully on attempt ${attemptNumber}`);
    });

    socket.on('visualization_ready', (data) => {
      if (data.visualization_type) {
        console.log(`Visualization is ready: Type = ${data.visualization_type}`);
      } else {
        console.warn(
          'Visualization type is missing in the received data:',
          data
        );
      }
      // Optional: Set a loading state or notify the user
    });

    // Listener for 'visualization_data'
    socket.on('visualization_data', (data) => {
      console.log('Received Visualization Data:', data);
      handleVisualizationData(data);
    });

    return () => {
      socket.off('connect');
      socket.off('final_response');
      socket.off('error');
      socket.off('reconnect_attempt');
      socket.off('connect_error');
      socket.off('reconnect');
      socket.off('visualization_ready');
      socket.off('visualization_data');
      socket.off('suggested_questions');
    };
  }, [
    handleSocketResponse,
    handleVisualizationData,
    handleSuggestedQuestions,
    selectedClass,
  ]);

  // Layout effect
  useEffect(() => {
    if (!reactFlowInstance) return;

    const nodesWithDimensions = reactFlowInstance.getNodes();
    const allNodesHaveDimensions = nodesWithDimensions.every(
      (node) => node.width && node.height
    );

    if (allNodesHaveDimensions) {
      const { nodes: layoutedNodes } = getLayoutedElements(
        nodesWithDimensions,
        edgesRef.current
      );

      // Compare positions to prevent unnecessary updates
      const positionsChanged = nodesWithDimensions.some((node) => {
        const layoutedNode = layoutedNodes.find((n) => n.id === node.id);
        return (
          node.position.x !== layoutedNode.position.x ||
          node.position.y !== layoutedNode.position.y
        );
      });

      if (positionsChanged) {
        setNodes((prevNodes) => {
          const updatedNodes = prevNodes.map((node) => {
            const layoutedNode = layoutedNodes.find((n) => n.id === node.id);
            return {
              ...node,
              position: layoutedNode.position,
            };
          });
          nodesRef.current = updatedNodes;
          return updatedNodes;
        });
      }
    } else {
      // Wait and try again if dimensions are not available yet
      const timeoutId = setTimeout(() => {
        const nodesWithDimensions = reactFlowInstance.getNodes();
        const allNodesHaveDimensions = nodesWithDimensions.every(
          (node) => node.width && node.height
        );

        if (allNodesHaveDimensions) {
          const { nodes: layoutedNodes } = getLayoutedElements(
            nodesWithDimensions,
            edgesRef.current
          );

          setNodes((prevNodes) => {
            const updatedNodes = prevNodes.map((node) => {
              const layoutedNode = layoutedNodes.find((n) => n.id === node.id);
              return {
                ...node,
                position: layoutedNode.position,
              };
            });
            nodesRef.current = updatedNodes;
            return updatedNodes;
          });
        }
      }, 50);

      return () => clearTimeout(timeoutId);
    }
  }, [nodes, edges, reactFlowInstance, getLayoutedElements]);

  // Logout function
  const logout = async () => {
    try {
      await auth.signOut(); // Sign out from Firebase
      navigate('/'); // Redirect to the landing page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Custom Node Components

  // UserNode component
  const UserNode = React.memo(({ data }) => (
    <div className="user-node">
      <div>{renderTextWithFormatting(data.label)}</div>
      <Handle type="source" position="bottom" />
    </div>
  ));

  // ChatbotNode component
  const ChatbotNode = React.memo(({ data }) => {
    const contentRef = useRef();

    return (
      <div className={`chatbot-node ${data?.hasVisual ? 'small-width' : ''}`}>
        <div className="chatbot-content" ref={contentRef}>
          {renderTextWithFormatting(data.label)}
        </div>
        {/* Icons at the bottom center */}
        <div className="chatbot-node-icons">
          <button
            className="chatbot-node-icon-button"
            onClick={() => copyToClipboard(contentRef.current)}
            title="Copy"
          >
            <FaCopy className="chatbot-node-icon copy-icon" />
          </button>
          <button
            className="chatbot-node-icon-button"
            onClick={() => flagContent(data.id, data.label)}
            title="Flag"
          >
            <FaFlag className="chatbot-node-icon flag-icon" />
          </button>
        </div>
        <Handle type="target" position="top" />
        <Handle type="source" position="right" id="right" />
        {/* Add this handle for the bottom */}
        <Handle type="source" position="bottom" id="bottom" />
      </div>
    );
  });

  // SuggestedQuestionsNode component
  const SuggestedQuestionsNode = React.memo(({ data }) => {
    const { questions } = data;

    return (
      <div className="suggested-questions-node">
        {questions.map((question, index) => (
          <button
            key={index}
            className="suggested-question-button"
            onClick={() => addUserMessage(question, true)}
          >
            {question}
          </button>
        ))}
        <Handle type="target" position="top" id="top" />
      </div>
    );
  });

  // VisualNode Component
  const VisualNode = React.memo(({ data }) => {
    const [isInteractive, setIsInteractive] = useState(false);

    const toggleInteractivity = () => {
      setIsInteractive((prev) => !prev);
    };

    const { plotData, visualizationType, funcExpression, plotMode } = data;

    const renderVisualization = useMemo(() => {
      if (!plotData) {
        return <div className="visual-placeholder">No Data Available</div>;
      }
      switch (visualizationType) {
        case 'generate_riemann':
          return (
            <Suspense fallback={<div>Loading Riemann Plot...</div>}>
              <RiemannSurfacePlot
                funcExpression={funcExpression}
                plotMode={plotMode}
                isInteractive={isInteractive}
              />
            </Suspense>
          );
        default:
          return (
            <div>
              Unsupported Visualization Type: {visualizationType}
            </div>
          );
      }
    }, [visualizationType, funcExpression, plotMode, isInteractive]);

    // Event handlers to stop propagation when interactive
    const onWheel = (e) => {
      if (isInteractive) {
        e.stopPropagation();
      }
    };

    const onMouseDown = (e) => {
      if (isInteractive) {
        e.stopPropagation();
      }
    };

    const onTouchStart = (e) => {
      if (isInteractive) {
        e.stopPropagation();
      }
    };

    return (
      <div
        className={`visual-node ${isInteractive ? 'interactive' : ''}`}
        onWheel={onWheel}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
      >
        {/* Interactivity Toggle Button */}
        <button
          className={`interactivity-toggle ${isInteractive ? 'active' : ''}`}
          onClick={toggleInteractivity}
          title={isInteractive ? 'Lock Interaction' : 'Unlock Interaction'}
        >
          {isInteractive ? '🔓' : '🔒'}
        </button>

        {/* Overlay when not interactive */}
        {!isInteractive && (
          <div className="interaction-blocker">
            <div className="overlay-text">Click the lock to interact</div>
          </div>
        )}

        {/* Visualization Content */}
        {renderVisualization}
        <Handle type="target" position="top" />
      </div>
    );
  });

  // Initial Options Node Component
  const InitialOptionsNode = React.memo(({ data }) => {
    const handleOptionClick = useCallback(
      (optionText) => {
        data.onOptionClick(optionText);
      },
      [data.onOptionClick]
    );

    const { prompts, images, headings } =
      classInitialOptions[selectedClass] || classInitialOptions['default_class'];

    const optionCards = useMemo(
      () =>
        prompts.map((optionText, index) => (
          <OptionCard
            key={index}
            heading={headings[index]}
            text={optionText}
            onClick={() => handleOptionClick(optionText)}
            imageSrc={images[index]}
          />
        )),
      [handleOptionClick, prompts, images, headings]
    );

    return (
      <div className="initial-options-node">
        <div className="options-container">{optionCards}</div>
      </div>
    );
  });

  // OptionCard component
  const OptionCard = React.memo(({ heading, text, onClick, imageSrc }) => {
    return (
      <div className="option-card" onClick={onClick}>
        {/* Add heading above the image */}
        <h3 className="option-card-heading">{heading}</h3>
        <img src={imageSrc} alt="Option" />
        <p>{text}</p>
      </div>
    );
  });

  // Define nodeTypes
  const nodeTypes = useMemo(
    () => ({
      user: UserNode,
      chatbot: ChatbotNode,
      reference: ReferenceNode,
      visual: VisualNode, // Include the VisualNode
      initialOptions: InitialOptionsNode,
      complexFunctionPlotter: (props) => (
        <ComplexFunctionPlotterNode
          {...props}
          setIsHoveringOverPlotter={setIsHoveringOverPlotter}
        />
      ),
      suggestedQuestions: (props) => (
        <SuggestedQuestionsNode {...props} addUserMessage={addUserMessage} />
      ),
    }),
    [addUserMessage]
  );

  // Function to copy content to clipboard
  const copyToClipboard = (contentElement) => {
    if (contentElement) {
      const text = contentElement.innerText;
      navigator.clipboard.writeText(text).then(
        () => {
          console.log('Text copied to clipboard');
        },
        (err) => {
          console.error('Failed to copy text: ', err);
        }
      );
    } else {
      console.error('Content element is invalid');
    }
  };

  // Function to flag content
  const flagContent = (id, content) => {
    console.log(`Content with ID ${id} has been flagged.`);
    console.log(`Flagged content: ${content}`);
  };

  return (
    <div className={`newgpt-container ${isNightMode ? 'night-mode' : ''}`}>
      {/* ReactFlow as full background */}
      <div className="flow-wrapper">
        <ReactFlow
          ref={flowRef}
          nodes={nodes}
          edges={edges}
          onConnect={(params) => setEdges((eds) => addEdge(params, eds))}
          transform={transform}
          zoomOnScroll={false}
          zoomOnPinch={true}
          panOnScroll={!isHoveringOverPlotter} // Disable panOnScroll when hovering

          nodeTypes={nodeTypes}
          fitView
          onInit={(instance) => {
            setReactFlowInstance(instance);
          }}
          className="reactflow-background"
        >
          <Background
            color={isNightMode ? '#ffffff' : '#000000'}
            gap={16}
            variant="dots"
          />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </div>
      <div className="sidebar-menu">
      {/* <div className="logo-placeholder">
  <img src={getClassLogo()} alt="Logo" className="logo-image" style={{ width: '200px', height: '200px' }} />
</div> */}

        {/* Greeting and roles display */}
        <h2 className="centered">Hello, {userInfo.firstName}</h2>
        <h4 className="centered">
          Role: <strong>{userInfo.roles.join(', ')}</strong> in{' '}
          {userInfo.courses.length > 0
            ? userInfo.courses.join(', ')
            : 'No courses assigned.'}
        </h4>

        <button className="menu-button" onClick={() => setShowSettingsModal(true)}>
          🔧 Settings
        </button>

        <button className="menu-button" onClick={openPrivacyOverlay}>
          🔒 Privacy Policy
        </button>

        <button
          className="menu-button"
          onClick={() =>
            (window.location.href =
              'https://edstem.org/us/courses/58082/discussion/5331907')
          }
        >
          🐞 Report Bugs and Request Features
        </button>

        <button className="logout-button" onClick={logout}>
          Logout
        </button>
      </div>

      {/* Settings Modal */}
      {showSettingsModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Settings</h2>
            <button className="modal-button" onClick={toggleNightMode}>
              {isNightMode ? '🌞 Light Mode' : '🌙 Night Mode'}
            </button>
            <button
              className="modal-button"
              onClick={() => setShowSettingsModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="floating-bar">
        <input
          type="text"
          className="floating-input"
          placeholder="Type your question here..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && input.trim()) {
              event.preventDefault();
              addUserMessage();
            }
          }}
        />
        <button className="floating-button" onClick={() => addUserMessage(input)}>
          <FaPaperPlane />
        </button>

        <button
          className="refresh-button"
          onClick={() => {
            setNodes([
              {
                id: 'initial-options',
                type: 'initialOptions',
                data: { onOptionClick: handleOptionClick },
                position: { x: 0, y: 0 },
              },
            ]);
            setEdges([]);
            nextIdRef.current = 1;
          }}
        >
          <FaSyncAlt />
        </button>
      </div>
    </div>
  );
}

export default NewerGPT;
