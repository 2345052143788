import React, { useEffect, useState } from 'react';
import { db } from './firebase'; // Adjust the path as necessary
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  doc,          // Import doc
  updateDoc,    // Import updateDoc
} from 'firebase/firestore';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import {
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';

const rolesOptions = ['admin', 'instructor', 'TF', 'student'];
const coursesOptions = ['physics15a', 'am104', 'htgaa'];

function UsersTable({ selectedCourse }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false); // Initialize as false
  const [error, setError] = useState('');
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedCourse) {
        setLoading(true);
        setError('');
        try {
          const usersCollection = collection(db, 'users');

          // First, get the total count of documents for pagination
          const countQuery = query(
            usersCollection,
            where('courses', 'array-contains', selectedCourse)
          );
          const countSnapshot = await getDocs(countQuery);
          setTotalRows(countSnapshot.size);

          // Fetch paginated data
          let q = query(
            usersCollection,
            where('courses', 'array-contains', selectedCourse),
            limit(pageSize)
          );

          if (page > 0 && lastVisibleDoc) {
            q = query(
              usersCollection,
              where('courses', 'array-contains', selectedCourse),
              startAfter(lastVisibleDoc),
              limit(pageSize)
            );
          }

          const snapshot = await getDocs(q);

          if (!snapshot.empty) {
            const lastVisible = snapshot.docs[snapshot.docs.length - 1];
            setLastVisibleDoc(lastVisible);

            const usersList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setUsers(usersList);
          } else {
            setUsers([]);
          }
        } catch (error) {
          console.error('Error fetching users:', error);
          setError('Failed to load data.');
        } finally {
          setLoading(false);
        }
      } else {
        setUsers([]);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCourse, page, pageSize]);

  const handleCellEditCommit = async (params) => {
    const { id, field, value } = params;
    try {
      const userDocRef = doc(db, 'users', id);
      await updateDoc(userDocRef, {
        [field]: value,
      });
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 150,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 150,
      editable: true,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      editable: true,
      valueGetter: (params) => params.row.roles || [],
      renderCell: (params) => (params.value || []).join(', '),
      renderEditCell: (params) => (
        <Autocomplete
          multiple
          options={rolesOptions}
          value={params.value || []}
          onChange={(event, newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: newValue,
            });
          }}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
    {
      field: 'courses',
      headerName: 'Courses',
      width: 200,
      editable: true,
      valueGetter: (params) => params.row.courses || [],
      renderCell: (params) => (params.value || []).join(', '),
      renderEditCell: (params) => (
        <Autocomplete
          multiple
          options={coursesOptions}
          value={params.value || []}
          onChange={(event, newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: newValue,
            });
          }}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>;
  }

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={users}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        pagination
        paginationMode="server"
        rowCount={totalRows}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          setPage(0);
        }}
        onCellEditCommit={handleCellEditCommit}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
      />
    </div>
  );
}

export default UsersTable;
