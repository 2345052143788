// statusService.js

export const fetchOpenAIStatus = async () => {
    try {
      const response = await fetch('https://status.openai.com/api/v2/summary.json');
      if (!response.ok) throw new Error('Failed to fetch OpenAI status');
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching OpenAI status:', error);
      return null; // Return null in case of an error
    }
  };
  