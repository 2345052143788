// LandingPage.js
import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth'; 
import { auth } from './firebase'; 
import './LandingPage.css';
import image1 from './img1.png';
import image2 from './img2.png';
import image3 from './img3.png';
import { fetchOpenAIStatus } from './statusService'; // Import the fetch function

const slides = [
  { src: image1, bgColor: '#6c757d' },
  { src: image2, bgColor: '#343a40' },
  { src: image3, bgColor: '#495057' },
];

// Define local services and their initial statuses
const initialLocalServices = [
  { name: 'PDF Viewer', status: 'Operational' }, // or 'Down'
  // Add more services as needed
];

const LandingPage = () => {
  const [isSignUp, setIsSignUp] = useState(false); 
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(true);
  const [error, setError] = useState('');
  const [isVersionPopupVisible, setIsVersionPopupVisible] = useState(false);
  const [isStatusPopupVisible, setIsStatusPopupVisible] = useState(false);
  const [lastChecked, setLastChecked] = useState(new Date());
  const [statusData, setStatusData] = useState({
    openAIStatus: 'Unknown',
    openAIComponents: [],
    openAIIncidents: [],
    localServices: initialLocalServices,
  });
  const [statusError, setStatusError] = useState('');

  const handleToggleForm = (formType) => {
    setIsSignUp(formType === 'signup');
    setIsForgotPassword(formType === 'forgot');
    setError('');
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idTokenResult = await userCredential.user.getIdTokenResult();
      const claims = idTokenResult.claims;

      // Check if user has necessary privileges
      if (claims.student || claims.TF || claims.developer || claims.admin) {
        window.location.href = '/GPT'; // Redirect to /GPT if user has the correct claims
      } else {
        setError('Permissions invalid, you are on the waitlist.');
      }
    } catch (err) {
      setError('Incorrect email or password. Please try again.');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setIsProgressBarVisible(false); // Hide progress bar during transition

      setTimeout(() => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
        setIsTransitioning(false);
        setIsProgressBarVisible(true); // Show progress bar after transition
      }, 1000); // Transition time
    }, 10000); // Slower animation interval

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const loadStatus = async () => {
      const statusData = await fetchOpenAIStatus();
      if (statusData) {
        setStatusData((prevStatusData) => ({
          ...prevStatusData,
          openAIStatus: statusData.status.description,
          openAIComponents: statusData.components,
          openAIIncidents: statusData.incidents,
        }));
      } else {
        setStatusError('Unable to retrieve OpenAI status.');
        setStatusData((prevStatusData) => ({
          ...prevStatusData,
          openAIStatus: 'Unknown',
        }));
      }
      setLastChecked(new Date());
    };

    loadStatus();

    // Refresh status every 5 minutes
    const statusInterval = setInterval(loadStatus, 300000); // 5 minutes in ms

    // Update lastChecked time every minute
    const checkInterval = setInterval(() => {
      setLastChecked(new Date());
    }, 60000);

    return () => {
      clearInterval(statusInterval);
      clearInterval(checkInterval);
    };
  }, []);

  const formatTime = (date) => {
    const now = new Date();
    const diffMs = now - date;
    const diffMinutes = Math.floor(diffMs / 60000);
    if (diffMinutes < 1) {
      return 'Just now';
    } else if (diffMinutes === 1) {
      return '1 minute ago';
    } else {
      return `${diffMinutes} minutes ago`;
    }
  };

  // Compute overall status message
  const computeStatusMessage = () => {
    const { openAIStatus, localServices } = statusData;
    let overallStatus = 'All systems operational';
    let issues = [];

    if (openAIStatus !== 'All Systems Operational') {
      issues.push('OpenAI API');
    }

    localServices.forEach((service) => {
      if (service.status !== 'Operational') {
        issues.push(service.name);
      }
    });

    if (issues.length > 0) {
      overallStatus = 'Core functionality operational (' + issues.join(', ') + ' down)';
    }

    return overallStatus;
  };

  const overallStatusMessage = computeStatusMessage();

  const { src, bgColor } = slides[currentSlideIndex];

  return (
    <div className="landing-page">
      <div className="landing-card">
        {/* Login Side */}
        <div className="login-side">
          <div className={`form-container ${isSignUp ? 'slide-left' : isForgotPassword ? 'slide-right' : ''}`}>
            {/* Login Form */}
            <form className="login-form" onSubmit={handleSignIn}>
              <h2>Welcome Back</h2>
              <label>Email</label>
              <input name="email" type="email" placeholder="Your Harvard or MIT Email" required />
              <label>Password</label>
              <input name="password" type="password" placeholder="Your password" required />
              <div className="forgot-password-container">
                <span onClick={() => handleToggleForm('forgot')} className="forgot-password">Forgot password?</span>
              </div>
              <button type="submit" className="login-button">Sign In</button>
              <div className="create-account">
                <span onClick={() => handleToggleForm('signup')} className="create-account-link">New here? Create an account!</span>
              </div>
              {error && <p className="error-message">{error}</p>}
            </form>
            {/* Sign Up Form */}
            <form className="signup-form">
              <h2>Create Account</h2>
              <label>Name</label>
              <input type="text" placeholder="Your Full Name" required />
              <label>Class Code</label>
              <input type="text" placeholder="Enter Class Code" required />
              <label>Email</label>
              <input type="email" placeholder="Your Harvard or MIT Email" required />
              <div className="password-fields">
                <label>Password</label>
                <input type="password" placeholder="Create a password" required />
                <label>Confirm Password</label>
                <input type="password" placeholder="Confirm your password" required />
              </div>
              <button type="submit" className="login-button">Sign Up</button>
              <div className="create-account">
                <span onClick={() => handleToggleForm('login')} className="create-account-link">Already have an account? Return.</span>
              </div>
            </form>
            {/* Forgot Password Form */}
            <form className="forgot-password-form">
              <h2>Reset Password</h2>
              <label>Email</label>
              <input type="email" placeholder="Your Harvard or MIT Email" required />
              <button type="submit" className="login-button">Send Recovery Email</button>
              <div className="create-account">
                <span onClick={() => handleToggleForm('login')} className="create-account-link">Remembered? Go back!</span>
              </div>
            </form>
          </div>
        </div>
        {/* Graphic Side */}
        <div className={`graphic-side ${isTransitioning ? 'transitioning' : ''}`} style={{ backgroundColor: bgColor }}>
          <img src={src} alt="Slide" className="rotating-image" />
          {isProgressBarVisible && <div className="progress-bar"></div>}
        </div>
      </div>
      {/* Version Footer */}
      <div className="version-footer" onClick={() => setIsVersionPopupVisible(!isVersionPopupVisible)}>
        Version 1.0.1 - Build 20241113
      </div>
      {/* Version Pop-up */}
      {isVersionPopupVisible && (
        <div className="version-popup visible">
          <button className="close-popup" onClick={() => setIsVersionPopupVisible(false)}>X</button>
          <h3>Version History</h3>
          <p><strong> Version 1.0.0 (Formerly 4.10) - Build 20241113</strong></p>
          <p>Main Changes:</p>
          <p>- Addressing Major Outage. Fixed Formatting and Interface</p>
          <p><strong>Version 0.9.9</strong></p>
          <p>Main Changes:</p>
          <p>- Beta testing features, added new graphical interface, bug fixes.</p>
          <p><strong>Version 0.9.8</strong></p>
          <p>Main Changes:</p>
          <p>- First feature-complete build for alpha testers, including account creation and UI enhancements.</p>
        </div>
      )}
      {/* Status Footer */}
      <div 
        className={`status-footer ${overallStatusMessage === 'All systems operational' ? 'status-active' : 'status-inactive'}`}
        onClick={() => setIsStatusPopupVisible(!isStatusPopupVisible)}
      >
        <span className="status-text">Status: {overallStatusMessage}</span>
        <br />
        <span className="status-time">Last checked: {formatTime(lastChecked)}.</span>
      </div>

      {/* Status Pop-up */}
      {isStatusPopupVisible && (
        <div className="status-popup visible">
          <button className="close-popup" onClick={() => setIsStatusPopupVisible(false)}>X</button>
          <h3>System Status</h3>
          {statusError ? (
            <p className="error-message">{statusError}</p>
          ) : (
            <div>
              <p><strong>OpenAI Status:</strong> {statusData.openAIStatus}</p>
              <p><strong>Last Checked:</strong> {formatTime(lastChecked)}</p>
              <p><strong>OpenAI Components:</strong></p>
              <ul>
                {statusData.openAIComponents.map((component) => (
                  <li key={component.id}>
                    {component.name}: {component.status.replace('_', ' ')}
                  </li>
                ))}
              </ul>
              {statusData.openAIIncidents.length > 0 && (
                <div>
                  <p><strong>Incidents:</strong></p>
                  <ul>
                    {statusData.openAIIncidents.map((incident) => (
                      <li key={incident.id}>{incident.name}</li>
                    ))}
                  </ul>
                </div>
              )}
              <p><strong>Local Services:</strong></p>
              <ul>
                {statusData.localServices.map((service) => (
                  <li key={service.name}>
                    {service.name}: {service.status}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LandingPage;
