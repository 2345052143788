import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import axios from 'axios';
const DEBUG_MODE = true; // Set to true for local development, false for production

// Define the API base URL based on the debug mode
// const API_BASE_URL = DEBUG_MODE ? 'http://localhost:8080' : 'https://gptharvard.uc.r.appspot.com';

const RiemannSurfacePlot = ({ funcExpression, plotMode, isInteractive, initialPlotData }) => {
  const [plotData, setPlotData] = useState(initialPlotData || null);  // Start with initial plot data if provided
  const [errorMessage, setErrorMessage] = useState('');  // State for error messages
  const [loading, setLoading] = useState(true);  // State for loading

  // Fetch new plot data when funcExpression changes
  useEffect(() => {
    if (!funcExpression) {
      setErrorMessage('Missing function expression.');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setErrorMessage('');  // Reset any previous error message

      try {
        const response = await axios.post('https://gptharvard.uc.r.appspot.com/generate_data', {
          // const response = await axios.post('https://gptharvard.uc.r.appspot.com/generate_data', {

          funcExpression
        });

        if (response.data.error) {
          setErrorMessage(response.data.error);
          setPlotData(null);  // Clear plot data on error
        } else {
          setPlotData(response.data);  // Update with new plot data
        }
      } catch (error) {
        setErrorMessage('Error fetching data from the server.');
        setPlotData(null);  // Clear plot data on error
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [funcExpression]);

  if (loading) return <div>Loading...</div>;
  if (!plotData) return <div>{errorMessage || 'No data available'}</div>;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Plot
        data={[
          {
            x: plotData.real,
            y: plotData.imag,
            z: plotMode === 'real' ? plotData.real_w : plotData.imag_w,
            type: 'surface',
            colorscale: 'Viridis',
          },
        ]}
        layout={{
          autosize: true,
          margin: { l: 0, r: 0, b: 0, t: 0 },
          scene: {
            xaxis: { title: 'Re(z)' },
            yaxis: { title: 'Im(z)' },
            zaxis: { title: plotMode === 'real' ? 'Re(f(z))' : 'Im(f(z))' },
          },
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%',pointerEvents: isInteractive ? 'auto' : 'none', }}
      />
    </div>
  );
};

export default RiemannSurfacePlot;
