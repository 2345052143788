import React, { useState, useCallback } from 'react';
import { Handle } from 'react-flow-renderer';

const ReferenceNode = React.memo(({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [pdfURL, setPdfURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Helper function to clean the filename
  const getCleanFilename = useCallback((filenameWithPrefix) => {
    // Remove leading '[x] ' prefix
    return filenameWithPrefix.replace(/^\[\d+\]\s*/, '');
  }, []);

  const handleLoadClick = useCallback(() => {
    setLoading(true);
    setError(null);
    try {
      // Get the first reference and clean the filename
      const filenameWithPrefix = data.references[0];
      const filename = getCleanFilename(filenameWithPrefix);

      // Construct the PDF URL
      const pdfEndpoint = `http://localhost:8080/get_pdf?filename=${encodeURIComponent(filename)}`;

      // Set the PDF URL for the embed
      setPdfURL(pdfEndpoint);
      setIsExpanded(true);
    } catch (error) {
      console.error('Error fetching PDF:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [data.references, getCleanFilename]);

  return (
    <div className="reference-node">
      <h4>References</h4>
      <ul>
        {data.references.map((ref, index) => {
          const filename = getCleanFilename(ref);
          return <li key={index}>{filename}</li>;
        })}
      </ul>
      {/* <button onClick={handleLoadClick} disabled={loading}>
        {loading ? 'Loading...' : 'Load'}
      </button> */}
      {error && <div className="error-message">{error}</div>}
      {isExpanded && pdfURL && (
        <div className="pdf-viewer">
          <iframe
            src={pdfURL}
            width="100%"
            height="500px"
            style={{ border: 'none' }}
            title="PDF Preview"
          ></iframe>
        </div>
      )}
      <Handle type="target" position="left" />
    </div>
  );
});

export default ReferenceNode;
