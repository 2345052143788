// ComplexFunctionPlotterNode.js
import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import axios from 'axios';
import './ComplexFunctionPlotterNode.css';

const ComplexFunctionPlotterNode = ({ data,setIsHoveringOverPlotter }) => {
  const [functionStr, setFunctionStr] = useState(
    data.funcExpression 
    || 'z**2'
  );
  const handleMouseEnter = () => {
    setIsHoveringOverPlotter(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringOverPlotter(false);
  };
  const [resolution, setResolution] = useState(1000);
  const [plotData, setPlotData] = useState(data.plotData || null);
  const [error, setError] = useState(null);
  const [xRange, setXRange] = useState([-5, 5]);
  const [yRange, setYRange] = useState([-5, 5]);
  const [isLoading, setIsLoading] = useState(false);
  const plotRef = useRef(null);
  const [isInteractive, setIsInteractive] = useState(false);

  // Fetch data from the backend
  const fetchData = async (x_range = xRange, y_range = yRange) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        'https://gptharvard.uc.r.appspot.com/compute-transformation',
        {
          function: functionStr,
          resolution: resolution,
          x_range: x_range,
          y_range: y_range,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setPlotData(response.data);
      setError(null);
    } catch (err) {
      setError('Server error');
      setPlotData(null);
    }
    setIsLoading(false);
  };

  // Fetch data whenever the function string changes
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionStr, resolution]);

  // Handle zoom and pan events for re-fetching data with new ranges
  const handleRelayout = (event) => {
    const newXRange = [
      event['xaxis.range[0]'] || xRange[0],
      event['xaxis.range[1]'] || xRange[1],
    ];
    const newYRange = [
      event['yaxis.range[0]'] || yRange[0],
      event['yaxis.range[1]'] || yRange[1],
    ];
    setXRange(newXRange);
    setYRange(newYRange);
    fetchData(newXRange, newYRange);
  };

  const toggleInteractivity = () => {
    setIsInteractive((prev) => !prev);
  };

  return (
    <div
      className={`complex-function-plotter-node ${
        isInteractive ? 'interactive' : ''
      }`}
    >
      {/* Interactivity Toggle Button */}
      <button
        className={`interactivity-toggle ${isInteractive ? 'active' : ''}`}
        onClick={toggleInteractivity}
        title={isInteractive ? 'Lock Interaction' : 'Unlock Interaction'}
      >
        {isInteractive ? '🔓' : '🔒'}
      </button>

      {/* Overlay when not interactive */}
      {!isInteractive && (
        <div className="interaction-blocker">
          <div className="overlay-text">Click the lock to interact</div>
        </div>
      )}

      <div className="node-label">Complex Function Plotter</div>

      <div className="node-content">
        <div className="left-section">
          <div className="section-label">Input Function</div>
          <input
            type="text"
            value={functionStr}
            onChange={(e) => setFunctionStr(e.target.value)}
            placeholder="Enter complex function of z"
          />
          <div className="section-label">Resolution</div>
          <input
            type="number"
            value={resolution}
            onChange={(e) =>
              setResolution(parseInt(e.target.value) || 1000)
            }
            min="100"
            max="2000"
            step="100"
          />
          {error && <div className="error">{error}</div>}
          {isLoading && <div className="loading">Loading...</div>}
        </div>

        <div className="center-section">
          <div className="section-label">Visualization</div>
          {plotData ? (
            <Plot
              ref={plotRef}
              data={[]}
              layout={{
                autosize: true,
                title: `Domain Coloring of f(z) = ${functionStr}`,
                xaxis: { title: 'Re(z)', range: xRange },
                yaxis: {
                  title: 'Im(z)',
                  range: yRange,
                  scaleanchor: 'x',
                  scaleratio: 1,
                },
                images: [
                  {
                    source: plotData.image_base64,
                    xref: 'x',
                    yref: 'y',
                    x: plotData.x_range[0],
                    y: plotData.y_range[1],
                    sizex: plotData.x_range[1] - plotData.x_range[0],
                    sizey: plotData.y_range[1] - plotData.y_range[0],
                    sizing: 'stretch',
                    layer: 'below',
                  },
                ],
                margin: { t: 50, b: 50, l: 50, r: 50 },
              }}
              onRelayout={handleRelayout}
              useResizeHandler={true}
              style={{
                width: '100%',
                height: '500px',
                pointerEvents: isInteractive ? 'auto' : 'none',
              }}
              config={{ scrollZoom: true }}
            />
          ) : (
            <div className="no-data">No Data Available</div>
          )}
        </div>

        <div className="right-section">
          <div className="section-label">Tips</div>
          <ul className="tips-list">
            <li>
              Enter functions like <code>z**2</code> or <code>sin(z)</code>.
            </li>
            <li>
              Use <code>*</code> for multiplication and <code>**</code> for
              exponentiation.
            </li>
            <li>Zoom and pan to explore the plot.</li>
            <li>Adjust the resolution for more or less detail.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ComplexFunctionPlotterNode;
