// AdminPage.js
import React, { useEffect, useState } from 'react';
import './AdminPage.css';
import {
  FaSlidersH,
  FaFileUpload,
  FaUsers,
  FaChartBar,
  FaFolderOpen,
} from 'react-icons/fa';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import UsersTable from './UsersTable';

function DesignChatbot() {
  return (
    <div>
      <h3>Design and Edit Your Chatbot Offering</h3>
      <p>Here you can design and edit the chatbot's responses and behavior.</p>
    </div>
  );
}

function UploadFiles({ selectedCourse }) {
  // Implement your upload functionality here
  return (
    <div>
      <h3>Upload Your Files</h3>
      {/* Your upload form */}
    </div>
  );
}

function AdminPage({ userInfo }) {
  const [hasAccess, setHasAccess] = useState(false);
  const [isInstructor, setIsInstructor] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    const roles = userInfo.roles || [];
    if (
      roles.includes('admin') ||
      roles.includes('instructor') ||
      roles.includes('TF')
    ) {
      setHasAccess(true);
    }
    setIsInstructor(roles.includes('admin') || roles.includes('instructor'));
  }, [userInfo]);

  if (!hasAccess) {
    return <div className="access-denied">Access Denied</div>;
  }

  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
  };

  return (
    <div className="admin-page">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="admin-info">
          <h2>Welcome, {userInfo.firstName}!</h2>
          <h4>Courses:</h4>
          <div className="course-list">
            {userInfo.courses.length > 0 ? (
              userInfo.courses.map((course) => (
                <div
                  key={course}
                  className="course-box"
                  onClick={() => handleCourseSelect(course)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleCourseSelect(course);
                    }
                  }}
                  aria-label={`Select course ${course}`}
                >
                  <div className="logo-placeholder">
                    <FaFolderOpen size={20} color="#4285f4" />
                  </div>
                  <div className="course-name">{course}</div>
                </div>
              ))
            ) : (
              <p>No courses assigned.</p>
            )}
          </div>
        </div>
      </div>

      {/* Main content area */}
      <div className="content">
        {!selectedCourse ? (
          <div className="select-course-message">
            <h3>Please select a course to continue.</h3>
          </div>
        ) : (
          <div className="admin-tabs">
            <h3>Managing Course: {selectedCourse}</h3>
            <Tabs>
              <TabList>
                <Tab>
                  <FaSlidersH size={16} style={{ marginRight: '8px' }} />
                  Design and Edit Chatbot
                </Tab>
                <Tab>
                  <FaFileUpload size={16} style={{ marginRight: '8px' }} />
                  Upload Files
                </Tab>
                <Tab>
                  <FaChartBar size={16} style={{ marginRight: '8px' }} />
                  Activity
                </Tab>
                {isInstructor && (
                  <Tab>
                    <FaUsers size={16} style={{ marginRight: '8px' }} />
                    Manage Users
                  </Tab>
                )}
              </TabList>

              <TabPanel>
                <DesignChatbot />
              </TabPanel>
              <TabPanel>
                <UploadFiles selectedCourse={selectedCourse} />
              </TabPanel>
              <TabPanel>
                <h3>Activity</h3>
                <p>List of recent changes or actions taken.</p>
                {/* Implement activity logs and statistics here */}
              </TabPanel>
              {isInstructor && (
                <TabPanel>
                  <h3>Manage Users</h3>
                  <UsersTable selectedCourse={selectedCourse} />
                </TabPanel>
              )}
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminPage;
